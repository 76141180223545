import { HttpClient, HttpResponse } from '@angular/common/http';
import { afterRender, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  catchError,
  map,
  Observable,
  of,
  ReplaySubject,
  retry,
  tap,
  throwError,
  timer,
} from 'rxjs';

import {
  Collection,
  GridConfig,
  QueryEntity,
  TagsEntity,
} from '../classes/collection';
import {
  ActiveFacetValue,
  Facet,
  QueryComponent,
  ServerFacetConfig,
  ValuesEntity,
} from '../classes/facet';
import { Product } from '../classes/utility';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './localstorage.service';
import { SessionStorageService } from './sessionstorage.service';

@Injectable({
  providedIn: 'root',
})
export class MercuryService {
  config: any;
  userId: string | null | undefined;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private router: Router,
    private environmentService: EnvironmentService
  ) {
    this.configObservable.subscribe((data: GridConfig) => {
      this.LAYOUT = data.grid;
      this.SPLIT_VALUE = data.delimiter;
      this.FACET_MAP = data.facetMapping;
      this.COLOUR_MAP = Object.fromEntries(
        Object.entries(data.colourMapping).map(([key, value]) => [
          key.toLowerCase(),
          value,
        ])
      );
      this.IMAGE_MAP = data.imageMapping;
      this.SIZE_MAP = data.sizeMapping;
      this.CURRENCY =
        data.currency || this.environmentService.environment.currency || 'AUD';
    });

    this.facetObservable.subscribe((data: any) => {
      this.facets = data;
    });

    afterRender(() => {
      let uid = window.localStorage.getItem('plutocracy-uid');
      if (!uid) {
        if (window.localStorage) {
          uid = crypto.randomUUID();
          window.localStorage.setItem('plutocracy-uid', uid);
        }
      }
      this.userId = uid;
    });
  }

  getProductByHandle(
    handle: string,
    preview: boolean = false
  ): Observable<Product> {
    this.addToRecentlyViewedProducts(handle);

    return (
      this.http.get(
        this.environmentService.environment.mercuryApiUrl +
          (preview
            ? '/api/shopify/product/preview/' + handle
            : '/api/shopify/product/handle/' + handle),
        {
          params: {
            bypassToken: this.environmentService.environment.bypassToken,
          },
        }
      ) as Observable<Product>
    ).pipe(
      retry({
        count: 3,
        delay: (err, attempt) => timer(1000 * attempt),
      }),
      map((x: Product) => this.mapProductFields(x)),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  mapProductFields(product: Product) {
    if (product.metafields?.PRP?.related) {
      try {
        product.metafields.PRP.related = JSON.parse(
          product.metafields.PRP.related
        );
      } catch {}
    }
    return product;
  }

  public getZoneByShortcode(shortcode: string) {
    return this.http
      .get(
        this.environmentService.environment.mercuryApiUrl +
          +'/zone/query/' +
          shortcode,
        {
          params: {
            ...(this.environmentService.environment.bypassToken && {
              bypassToken: this.environmentService.environment.bypassToken,
            }),
            ...(this.userId && { userId: this.userId }),
            ...(this.CURRENCY && { currency: this.CURRENCY }),
          },
        }
      )
      .pipe(
        retry({
          count: 3,
          delay: (err, attempt) => timer(300 * attempt),
        }),
        catchError((error) => {
          // Handle error if the retry fails or other errors occur
          return throwError(() => error);
        })
      ) as Observable<any>;
  }

  addToRecentlyViewedProducts(handle: string): boolean {
    try {
      let collection = JSON.parse(
        this.localStorage.getItem('recently') as string
      );
      collection = collection && collection.length ? collection : [handle];
      collection.unshift(handle);
      collection = collection.filter((item: any, pos: any, self: any) => {
        return self.indexOf(item) == pos;
      });
      try {
        this.localStorage.setItem('recently', JSON.stringify(collection));
      } catch {
        console.log('local storage unavailable');
      }
      return true;
    } catch {
      console.log("couldn't add product to recently viewed");
      return false;
    }
  }

  createElement(node: any) {
    if (node.type === 'text') {
      return node.value;
    } else if (node.type === 'paragraph') {
      const content = node.children
        .map((child: any) => this.createElement(child))
        .join('');
      return `<p>${content}</p>`;
    } else if (node.type === 'list') {
      const items = node.children
        .map((child: any) => this.createElement(child))
        .join('');
      return `<ul>${items}</ul>`;
    } else if (node.type === 'list-item') {
      const content = node.children
        .map((child: any) => this.createElement(child))
        .join('');
      return `<li>${content}</li>`;
    }
  }

  convertToHtml(json: any) {
    if (json) {
      try {
        const data = JSON.parse(json);
        const html = data.children
          .map((child: any) => this.createElement(child))
          .join('');

        return html;
      } catch (err) {}
    }
  }

  environment = this.environmentService.environment;
  SPLIT_VALUE: string = '_';
  FACET_MAP!: ServerFacetConfig[];
  COLOUR_MAP!: any;
  IMAGE_MAP!: any;
  SIZE_MAP!: any[];
  LAYOUT!: any;
  CURRENCY!: string;

  facetSubject: ReplaySubject<Facet[]> = new ReplaySubject<Facet[]>(1);
  facetObservable: Observable<Facet[]> = this.facetSubject.asObservable();

  configSubject: ReplaySubject<GridConfig> = new ReplaySubject<GridConfig>(1);
  configObservable: Observable<GridConfig> = this.configSubject.asObservable();

  activeFacets: ActiveFacetValue[] = [];

  selectedSubject: ReplaySubject<ActiveFacetValue[]> = new ReplaySubject<
    ActiveFacetValue[]
  >(1);
  selectedObservable: Observable<ActiveFacetValue[]> =
    this.selectedSubject.asObservable();

  facets: Facet[] = [];
  filters: any;
  collection: any;
  searchTerm: string = '';

  _interacted: boolean = false;
  price: any;

  removeSelectedActiveFacetValue(value: ActiveFacetValue) {
    if (this.facets.length == 0) {
      this.clearFilters();
      return;
    }

    for (let facet of this.facets) {
      for (let option of facet.values) {
        if (option.children) {
          for (let child of option.children) {
            if (child.value == value.value) {
              let active = this.activeFacets.find(
                (x: ActiveFacetValue) => x.value == value.value
              );
              if (active) {
                this.removeFilter(
                  option,
                  this.filters[active.type],
                  active.value
                );
                return;
              }
            }
          }
        }
        if (option?.value == value.value) {
          let active = this.activeFacets.find(
            (x: ActiveFacetValue) => x.value == value.value
          );

          if (active) {
            this.removeFilter(option, this.filters[active.type], active.value);
          }
        }
      }
    }
  }

  public clearFilters() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }

  private onlyUniqueLowercasedStrings(value: string, index: any, self: any[]) {
    return (
      value.length > 0 &&
      self.map((x: string) => x.toLowerCase()).indexOf(value.toLowerCase()) ===
        index
    );
  }

  public calculateFilters(queryParams: Params): QueryComponent[] {
    let selectedOptions = [];
    if (queryParams.type) {
      for (let group of queryParams.type.split(',')) {
        let tags = group.split('|');
        selectedOptions.push({
          type: 'type',
          value: tags,
        });
      }
    }
    if (queryParams.vendor) {
      for (let group of queryParams.vendor.split(',')) {
        let tags = group.split('|');
        selectedOptions.push({
          type: 'vendor',
          value: tags,
        });
      }
    }

    if (queryParams.tag) {
      let entries = this.processTagString(queryParams.tag);
      for (let tag of entries) {
        tag = Array.isArray(tag) ? tag : tag.split(',');
        selectedOptions.push({
          type: 'tag',
          value: tag,
        });
      }
    }
    return selectedOptions;
  }

  private processCollection(collection: Collection) {
    this.activeFacets = collection.query.flatMap((entry: QueryEntity) =>
      entry.value.map((value) => ({ type: entry.type, value }))
    );
    this.selectedSubject.next(this.activeFacets);
    const sizeFacets = this.calculateSizeFacets(collection);
    const tagFacets = this.calculateTagFacets(collection);
    const hierarchyFacets = this.calculateHierarchyFacets(collection);
    const vendorFacets = this.calculateVendorFacets(collection!.facets.vendors);
    const typeFacets = this.calculateTypeFacets(collection!.facets.types);
    this.facetSubject.next(
      [
        ...sizeFacets,
        ...tagFacets,
        ...vendorFacets,
        ...typeFacets,
        ...hierarchyFacets,
      ]
        .filter((x: Facet) => x.values.length > 0)
        .sort((a: Facet, b: Facet) => this.sortFacets(a, b))
        .filter((x: Facet) =>
          this.filterFacetsForOnlyActiveFacets(collection, x)
        )
    );
    return collection;
  }

  private sortFacets(a: Facet, b: Facet) {
    const foundOrderA =
      this.FACET_MAP.find((x) => x.facet == a.facet)?.index || 999;
    const foundOrderB =
      this.FACET_MAP.find((x) => x.facet == b.facet)?.index || 999;
    return foundOrderA - foundOrderB;
  }

  private filterFacetsForOnlyActiveFacets(
    collection: Collection,
    facet: Facet
  ) {
    const facetsConfig = collection?.config?.facets;
    const isFacetHidden =
      facetsConfig && facetsConfig[facet.facet]?.hidden === true;
    const isConfiguredFacetsOnly =
      this.LAYOUT.core?.collectionFilter?.config?.configuredFacetsOnly;
    const isFacetInMap = this.FACET_MAP.find((y) => facet.facet == y.facet);

    if (isFacetHidden) {
      return false;
    }

    if (isConfiguredFacetsOnly) {
      if (isFacetInMap) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  private calculateVendorFacets(rawVendors: any): Facet[] {
    const facetMapReference = this.FACET_MAP.find(
      (x: any) => x.type == 'vendor'
    );

    return [
      {
        type: 'vendor',
        title: facetMapReference?.title || facetMapReference?.facet || 'Vendor',
        hidden: facetMapReference?.hidden || false,
        facet: facetMapReference?.facet || 'Vendor',
        minimized: facetMapReference?.minimized || false,
        values: rawVendors
          .map((x: { vendor: string; count: number }) => x.vendor)
          .filter(this.onlyUniqueLowercasedStrings)
          .map((y: string) => {
            return {
              label: y,
              value: y,
              meta: '',
              type: 'vendor',
              active: this.isFilterActive(y),
            };
          })
          .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
          .sort((a: any, b: any) => {
            const order: any = this.FACET_MAP.find(
              (x: any) => x.facet == 'vendor'
            )?.sort;
            if (order) {
              return (
                (order.find((x: any) => x.id == a.value)?.i ?? 0) -
                (order.find((x: any) => x.id == b.value)?.i ?? 0)
              );
            }
            return 0;
          }),
      },
    ];
  }

  private calculateSizeFacets(collection: Collection): Facet[] {
    let rawTags = collection!.facets!.tags!.filter((x: TagsEntity) =>
      x.value.includes('%')
    );

    const facetMapReference = this.FACET_MAP.find(
      (x: any) => x.type == 'variant'
    );
    if (this.SIZE_MAP && facetMapReference) {
      return [
        {
          type: 'variant_size',
          title: facetMapReference?.title || 'Size',
          facet: facetMapReference?.facet,
          hidden: facetMapReference?.hidden || false,
          minimized: facetMapReference?.minimized || false,
          values: rawTags
            .filter(
              (x: { value: string; count: number }) =>
                !x.value.toLocaleLowerCase().includes('badge') &&
                x.value.split('%').length > 0
            )
            .map(
              (x: { value: string; count: number }) =>
                ({
                  label: x.value.split('%')[1],
                  value: x.value,
                  group: x.value.split('%')[0],
                  type: 'tag',
                  active: this.isFilterActive(x.value),
                } as ValuesEntity)
            )
            .sort((a: any, b: any) => {
              const mapA = this.SIZE_MAP.find(
                (x: any) => a.value.split('%')[0] == x.name
              );
              const objA = mapA?.values.indexOf(
                mapA.values.find((y: any) => y.label == a.value.split('%')[1])
              );
              const mapB = this.SIZE_MAP.find(
                (x: any) => b.value.split('%')[0] == x.name
              );
              const objB = mapB?.values.indexOf(
                mapB.values.find((y: any) => y.label == b.value.split('%')[1])
              );
              return objA - objB;
            }),
        },
      ];
    }
    return [];
  }

  private calculateTypeFacets(rawTypes: any): Facet[] {
    const facetMapReference = this.FACET_MAP.find((x: any) => x.type == 'type');
    if (facetMapReference) {
      return [
        {
          type: 'type',
          title:
            facetMapReference?.title ||
            facetMapReference?.facet ||
            'Product Type',
          facet: facetMapReference?.facet || 'Product Type',
          hidden: facetMapReference?.hidden || false,
          minimized: facetMapReference?.minimized || false,
          values: rawTypes
            .map((x: { type: string; count: number }) => x.type)
            .filter(this.onlyUniqueLowercasedStrings)
            .map((y: string) => {
              return {
                label: y,
                value: y,
                meta: '',
                type: 'type',
                active: this.isFilterActive(y),
              };
            })
            .sort((a: ValuesEntity, b: ValuesEntity) =>
              a.label > b.label ? 1 : -1
            )
            .sort((a: ValuesEntity, b: ValuesEntity) => {
              const order = facetMapReference?.sort;
              if (order) {
                return (
                  (order.find((x: any) => x.id == a.value)?.i ?? 0) -
                  (order.find((x: any) => x.id == b.value)?.i ?? 0)
                );
              }
              return 0;
            }),
        },
      ];
    }
    return [];
  }

  private calculateHierarchyFacets(rawTags: any): Facet[] {
    const filter = this.FACET_MAP.find((x: any) => x.type == 'hierarchy');

    if (filter) {
      return [
        {
          type: 'hierarchy',
          minimized: filter?.minimized || false,
          facet: filter?.facet,
          hidden: filter?.hidden || false,
          title: filter?.title || filter?.facet!,
          values: filter!.sort.map((y: any) => {
            return {
              value:
                y.children && y.children.length > 0
                  ? y.children.map((x: any) => x.id)
                  : y.id,
              label: y.id.split(this.SPLIT_VALUE)[1],
              active: this.isFilterActive(y.id),
              type: 'tag',
              children: y.children.map((x: any) => {
                return {
                  active: this.isFilterActive(x.id),
                  value: x.id,
                  type: 'tag',
                  label: x.id.split(this.SPLIT_VALUE)[1],
                };
              }),
            } as ValuesEntity;
          }),
        } as Facet,
      ].map((x: Facet) => {
        x.values.forEach((y) => {
          if (y.children && y.children.length == 0) {
            y.children = undefined;
          } else if (y.children && y.children.length > 0) {
            y.children = y.children?.filter((x) =>
              rawTags
                .filter((x: { value: string; count: number }) => x.value)
                .map((x: { value: string; count: number }) =>
                  x.value.toLocaleLowerCase()
                )
                .includes(x.value.toLocaleLowerCase())
            );
          }
          y.active =
            y.children && y.children.length > 0
              ? y.children?.every((z) => z.active) || false
              : false;
        });
        x.values = x.values.filter((y) => {
          if (y.children !== undefined) return true;
          if (!y?.value) return true;
          if (Array.isArray(y.value)) {
            return true;
          }

          return rawTags
            .filter((x: { value: string; count: number }) => x.value)
            .map((x: { value: string; count: number }) =>
              x.value.toLocaleLowerCase()
            )
            .includes(y.value.toLocaleLowerCase());
        });
        x.values = x.values.filter((y) => {
          if (y.children === undefined) return true;
          return y.children.length > 0;
        });

        return x;
      });
    }
    return [];
  }

  private calculateTagFacets(collection: Collection): Facet[] {
    const rawTags = collection!.facets!.tags!.filter((x: TagsEntity) =>
      x.value.includes(this.SPLIT_VALUE)
    );
    return rawTags
      .map(
        (x: { value: string; count: number }) =>
          x.value.split(this.SPLIT_VALUE)[0]
      )
      .filter(this.onlyUniqueLowercasedStrings)
      .filter((element: string) => {
        const found = this.FACET_MAP.find(
          (x: any) => x.facet.toLocaleLowerCase() == element.toLowerCase()
        );

        return !found?.hidden;
      })
      .map((element: string) => {
        const mapType = this.FACET_MAP.find(
          (x: any) => x.facet.toLocaleLowerCase() == element.toLowerCase()
        );

        return {
          type: this.computeType(mapType),
          minimized: mapType?.minimized || false,
          title: element,
          facet: mapType?.facet,
          values: rawTags
            .filter(
              (x: { value: string; count: number }) =>
                x.value.split(this.SPLIT_VALUE)[0] == element
            )
            .map((x: { value: string; count: number }) => x.value)
            .filter(this.onlyUniqueLowercasedStrings)
            .map((y: string) => {
              return {
                label: y.split(this.SPLIT_VALUE)[1],
                value: y,
                meta: this.computeMeta(
                  this.computeType(mapType),
                  y.split(this.SPLIT_VALUE)[1]
                ),
                type: 'tag',
                active: this.isFilterActive(y),
              };
            })
            .sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
            .sort((a: any, b: any) => {
              const order: any = this.FACET_MAP.find(
                (x: any) => x.facet.toLocaleLowerCase() == element.toLowerCase()
              )?.sort;
              if (order) {
                return (
                  (order.find((x: any) => x.id == a.value)?.i ?? 0) -
                  (order.find((x: any) => x.id == b.value)?.i ?? 0)
                );
              }
              return 0;
            }),
        } as Facet;
      });
  }

  private isFilterActive(value: string): boolean {
    return this.activeFacets.find(
      (x: ActiveFacetValue) => x.value.toLowerCase() == value.toLowerCase()
    )
      ? true
      : false;
  }

  private computeType(
    element: any
  ):
    | 'type'
    | 'vendor'
    | 'tag'
    | 'variant_size'
    | 'colour'
    | 'hierarchy'
    | null {
    if (!element) {
      return null;
    }
    if (!element.type) {
      return null;
    }
    return element.type;
  }

  public getSearchResults(
    query: string,
    filters: QueryComponent[],
    sort?: string,
    take?: number,
    skip?: number
  ): Observable<Collection> {
    return (
      this.http.get(
        this.environmentService.environment.mercuryApiUrl +
          '/search/query/' +
          query,
        {
          params: {
            ...(sort && { sort }),
            ...(this.environmentService.environment.bypassToken && {
              bypassToken: this.environmentService.environment.bypassToken,
            }),
            ...(filters.length > 0 && { query: JSON.stringify(filters) }),
            ...(take && { take }),
            ...(skip && { skip }),
            ...(!this.config && { grid_config: true }),
            ...(this.CURRENCY && { currency: this.CURRENCY }),
          },
        }
      ) as Observable<Collection>
    ).pipe(
      retry({
        count: 3,
        delay: (err, attempt) => timer(300 * attempt),
      }),
      tap((data: Collection) => {
        if (!this.config) {
          this.config = data.grid_config;
          this.configSubject.next(data.grid_config);
        }
      }),
      catchError((error) => {
        // Handle error if the retry fails or other errors occur
        return throwError(() => error);
      }),
      map((x: any) => ({
        ...x,
        products: x.products.map((y: any) => this.mapProductFields(y)),
      })),
      tap((data: any) => {
        this.collection = this.processCollection(data);
      })
    );
  }

  public getFilteredCollection(
    handle: string,
    filters: QueryComponent[] = [],
    sort?: string,
    take?: number,
    skip?: number
  ): Observable<Collection> {
    return (
      this.http.get(
        this.environmentService.environment.mercuryApiUrl +
          '/search/collection/' +
          handle,
        {
          params: {
            ...(sort && { sort }),
            ...(this.environmentService.environment.bypassToken && {
              bypassToken: this.environmentService.environment.bypassToken,
            }),
            ...(filters?.length > 0 && { query: JSON.stringify(filters) }),
            ...(this.CURRENCY && { currency: this.CURRENCY }),
            ...(!this.config && { grid_config: true }),
            ...(take && { take }),
            ...(skip && { skip }),
          },
        }
      ) as Observable<Collection>
    ).pipe(
      retry({
        count: 3,
        delay: (err, attempt) => timer(1000 * attempt),
      }),
      tap((data: Collection) => {
        if (!this.config) {
          this.config = data.grid_config;
          this.configSubject.next(data.grid_config);
        }
      }),
      catchError((error) => {
        // Handle error if the retry fails or other errors occur
        return throwError(() => error);
      }),
      map((x: any) => ({
        ...x,
        products: x.products.map((y: any) => this.mapProductFields(y)),
      })),
      tap((data: any) => {
        this.collection = this.processCollection(data);
      })
    );
  }

  public getProductsByHandles(handles: string[]): Observable<Product[]> {
    const cachedProducts: any[] = [];
    const missingHandles: string[] = [];

    // Check cache for each handle
    handles.forEach((handle) => {
      if (this.sessionStorage.getItemJson(handle)?.handle) {
        cachedProducts.push(this.sessionStorage.getItemJson(handle) as Product);
      } else {
        missingHandles.push(handle);
      }
    });

    if (missingHandles.length === 0) {
      return of(cachedProducts);
    }
    try {
      return this.http
        .get(
          this.environmentService.environment.mercuryApiUrl +
            '/search/product/handles/' +
            missingHandles.join(','),
          {
            params: {
              bypassToken: this.environmentService.environment.bypassToken,
            },
          }
        )
        .pipe(
          retry({
            count: 3,
            delay: (err, attempt) => timer(300 * attempt),
          }),
          map((products: any) => {
            if (products?.length > 0) {
              products.forEach((product: any) => {
                this.sessionStorage.setItemJson(
                  product.handle,
                  this.mapProductFields(product)
                );
              });
            }
            return handles
              .map((handle) => {
                return this.sessionStorage.getItemJson(handle) as Product;
              })
              .filter((x) => x !== null);
          })
        );
    } catch (err) {
      return of([]);
    }
  }

  public selectMultiFilter(filter: ValuesEntity) {
    if (!filter.children || filter.children.length == 0) {
      this.selectValueFromFacet(filter);
    } else {
      this.selectValueFromFacet(filter, true);
    }
  }

  public selectMultiFilterChild(child: ValuesEntity, filter: ValuesEntity) {
    if (filter.active) {
      this.removeSelectedActiveFacetValue(child);
    } else if (child.active) {
      // remove the child
      this.removeSelectedActiveFacetValue(child);
    } else if (filter.children?.find((x) => x.active)) {
      // todo - this should add to the existing group
      this.selectValueFromFacet(child, true);
    } else {
      this.selectValueFromFacet(child, false);
    }
  }

  public selectValueFromFacet(filter: ValuesEntity, orNotAnd: boolean = false) {
    filter.active = !filter.active;

    // de duplicate here
    const delimiter = orNotAnd ? '|' : ',';

    const typeFound = this.filters[filter.type];
    let filterValue = orNotAnd
      ? Array.isArray(filter.value)
        ? '(' +
          filter.value
            .filter((x) => filter.children?.find((y) => y.value === x))
            .join(delimiter) +
          ')'
        : filter.value
      : filter.value;

    if (!typeFound || typeFound.length === 0) {
      this.updateRouterQueryParams({ [filter.type]: filterValue, page: 1 });
      return;
    }
    const valueFound = this.valueExists(typeFound, filterValue);

    if (valueFound) {
      this.removeFilter(filter, typeFound, filterValue);
      return;
    }

    const appendedValue = this.appendValue(typeFound, filterValue, delimiter);
    this.updateRouterQueryParams({ [filter.type]: appendedValue, page: 1 });
  }

  private removeFilter(filter: ValuesEntity, type: string, value: string) {
    const updatedValue = this.removeValue(type, value);

    this.updateRouterQueryParams({
      [filter.type]: updatedValue || null,
      page: 1,
    });
    return;
  }

  private processTagString(input: string) {
    let parts;
    if (typeof input === 'string') {
      parts = input.split(',');
    } else {
      parts = input;
    }

    // Process each part
    const result = parts.map((part) => {
      // Check if the part contains parentheses
      if (part.includes('(') && part.includes(')')) {
        // Remove the parentheses and split by '|'
        return part.replace(/[()]/g, '').split('|');
      } else {
        // Return the part as is for single values
        return part;
      }
    });

    // Filter out empty strings, if any
    return result.filter((part) => part !== '');
  }

  private valueExists(typeValue: string, filterValue: string): boolean {
    // Split the strings by ',' to create arrays of values

    const typeValuesArray = typeValue.split(/[|,]/);
    const filterValuesArray = filterValue.split(/[|,]/);

    // Check if every element of filterValuesArray is included in typeValuesArray
    return filterValuesArray.every((filter) =>
      typeValuesArray.includes(filter)
    );
  }

  private removeValue(typeValue: string, filterValue: string): string {
    if (typeValue === filterValue) {
      return '';
    }
    // Regular expression to match content within parentheses and outside
    const regex = /\(([^)]*)\)|([^,]+)/g;
    let result = '';
    let match: RegExpExecArray | null;

    while ((match = regex.exec(typeValue)) !== null) {
      // Extract matching group, whether it's within parentheses or not
      const segment = match[1] !== undefined ? match[1] : match[2];
      if (segment) {
        // Split segment by both delimiters and filter out the filterValue
        const filteredSegment = segment
          .split(/[|,]/)
          .filter((value) => value.trim() !== filterValue)
          .join(match[1] !== undefined ? '|' : ',');

        // Append filtered segment to result
        if (filteredSegment) {
          // Determine correct delimiter (keep parentheses if it was a parenthesized segment)
          const delimiter =
            match[1] !== undefined ? `(${filteredSegment})` : filteredSegment;
          // Append to result with comma as separator for non-parenthesized parts
          result += result === '' ? delimiter : `,${delimiter}`;
        }
      }
    }

    // Cleanup: remove any leading/trailing commas or pipes left after removal
    return result
      .replace(/^,|,$/g, '')
      .replace(/\(\|/g, '(')
      .replace(/\|\)/g, ')')
      .replace(/,+/g, ',')
      .replace(/\|+/g, '|')
      .replace(/\(\)/g, '');
  }

  private appendValue(
    typeValue: string,
    filterValue: string,
    delimiter: string
  ): string {
    const valuesArray = typeValue.split(delimiter);
    valuesArray.push(filterValue);
    return valuesArray.join(delimiter);
  }

  private updateRouterQueryParams(params: any) {
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  private computeMeta(
    type: string | null,
    value: any
  ): undefined | { hex: string; img: string } {
    if (!type) {
      return;
    }
    if (type == 'colour') {
      return {
        hex: this.COLOUR_MAP[value.toLowerCase()] || value,
        img: this.IMAGE_MAP[value.toLowerCase()] || undefined,
      };
    }

    return;
  }
}
